import React, { useState } from 'react';
import 'components/contact/Contact.css';
import Layout from 'components/layout';
import Box from 'components/box';
import Footer from 'components/footer/Footer';
import Title from 'components/title';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { graphql } from 'gatsby';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useForm } from '../helpers/input-factory';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '50ch',
    },
  },
}));

const Contact = ({ data }) => {

  const { onChange, onSubmit, result } = useForm({ data: data.contactJson.fr })
  const locales = data.contactJson.fr;
  const classes = useStyles();

  return (
    <Layout>
      <div className="layout-div">
        <Title as="h2" size="large">
          {locales.title}
        </Title>
      </div>
      <div style={{ height: '40vh' }}>
        <Box>
          <form name="contact"
                method="POST"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                className={classes.root}
                onSubmit={onSubmit}>
            <input type="hidden" name="form-name" value="contact"/>
            <TextField name="name" label={locales.name} required onChange={onChange} />
            <TextField type={'email'} name="email" label={locales.email} required onChange={onChange}/>
            <div>
            <TextField type={'textarea'} name="message" fullWidth
                          placeholder={locales.placeholderMessage}
                          multiline
                          rows={2}
                          rowsMax={4} required onChange={onChange}/>
            </div>
            <Button type="submit" variant="contained">{locales.send}</Button>
          </form>

          <br/>
          <span className="source-light">{result}</span>
        </Box>
      </div>
      <div style={{ height: '20vh' }}/>
      <Footer footers={locales.footers}/>
    </Layout>
  );
};

export default Contact;

export const query = graphql`
  query ContactQuery {
    contactJson {
      fr {
        title
        errorMessage
        successfulMessage
        name
        email
        placeholderMessage
        send
        content {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    homeJson {
      fr {
        footers {
          title
          description {
            label
            link
          }
        }
      }
    }
  }
`;

export const ViewContact = (props) => {
  return (
    <div>
    {
      props.locales &&
        <TextField name="name" label={props.locales.name} required onChange={props.handleChange}/>
    }
    </div>
  );
}

import { useState } from 'react';

export const useForm = ({ data }) => {

  const [result, setResult] = useState('');
  const [state, setState] = useState({});

  function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => {
        setResult(data.successfulMessage);
        // navigate(form.getAttribute('action'))
      })
      .catch((error) => setResult(data.errorMessage));
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return {
    onSubmit: handleSubmit,
    onChange: handleChange,
    result
  }
};
